export default [
  /* {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
  }, */
  {
    header: "menu_name.metaverse",
  },
  {
    title: "menu_name.hall",
    route: "hall",
    icon: "GridIcon",
  },
  {
    title: "menu_name.space",
    route: "ListStand",
    icon: "BoxIcon",
  },
  {
    title: "menu_name.events",
    icon: "CalendarIcon",
    children: [
      {
        title: "menu_name.webinar_live",
        route: "conference",
        icon: "CircleIcon",
      },
    ],
  },
  {
    title: "menu_name.personalization",
    icon: "PenToolIcon",
    children: [
      {
        title: "menu_name.pop_up_banner",
        route: "totem",
        icon: "CircleIcon",
      },
      {
        title: "menu_name.poster",
        route: "personalizations",
        icon: "CircleIcon",
      },
    ],
  },
  {
    title: "menu_name.attendees",
    route: "users",
    icon: "UsersIcon",
  },
  {
    header: "menu_name.account",
  },
  {
    title: "menu_name.users",
    route: "editors",
    icon: "UserPlusIcon",
  },
  {
    title: "menu_name.settings",
    route: "settings",
    icon: "SettingsIcon",
  },
  {
    title: "menu_name.statistics",
    route: "stats",
    icon: "ActivityIcon",
  },
  {
    header: "menu_name.help_center",
  },
  {
    title: "menu_name.tutorial",
    route: "tutorials",
    icon: "LifeBuoyIcon",
  },
  {
    title: "menu_name.community",
    external:
      "https://discord.com/channels/1087288750545043547/1087288751144833046",
    svg: "discord",
  },
  {
    title: "menu_name.roadmap",
    external: "https://fairers.productlift.dev",
    svg: "roadmap",
  },

  /*{
    title: "menu_name.totem",
    route: "totem",
    icon: "ColumnsIcon",
  },
  {
    title: "menu_name.personalization",
    route: "personalizations",
    icon: "ImageIcon",
  },
  {
    title: "menu_name.meeting_point",
    route: "ListPoints",
    icon: "RepeatIcon",
  },
  {
    title: "menu_name.conference",
    route: "conference",
    icon: "VideoIcon",
  },
  {
    title: "menu_name.users",
    route: "users",
    icon: "EyeIcon",
  },
  {
    header: "menu_name.definition",
  },
  {
    title: "menu_name.notication",
    route: "notifications",
    icon: "BellIcon",
  },
  {
    title: "menu_name.editor",
    route: "editors",
    icon: "Edit3Icon",
  },
  {
    title: "menu_name.statistics",
    route: "stats",
    icon: "ActivityIcon",
  },
  {
    title: "menu_name.settings",
    route: "settings",
    icon: "SettingsIcon",
  }, */
];
